import React, { lazy } from 'react';
import { Redirect, Route, Switch } from 'react-router';
import { NotAuthRoute, PrivateRoute } from 'components';
import { URLS } from 'common/urls';
import {
    EntryMediaFilePage,
    MobileContactImport,
    Media,
    SearchPage,
    ThankYouPage
} from 'pages';
import RemoveMyDataPage from 'pages/RemoveMyDataPage';
import donation from 'pages/Donation';
import Privacy from 'pages/Privacy';
import Terms from 'pages/Terms';

const AllAboutMe = lazy(() => import('pages/AllAboutMe').then((page) => page));
const Timeline = lazy(() => import('pages/Timeline').then((page) => page));
const Book = lazy(() => import('pages/Book').then((page) => page));
const Entry = lazy(() => import('pages/Entry').then((page) => page));
const JournalTags = lazy(() =>
    import('pages/JournalsAndTags').then((page) => page)
);
const Login = lazy(() => import('pages/Login/Login').then((page) => page));
const SignUp = lazy(() => import('pages/SignUp/SignUp').then((page) => page));
const Verify = lazy(() => import('pages/Verify/Verify').then((page) => page));
const ForgotPassword = lazy(() =>
    import('pages/ForgotPassword/ForgotPassword')
);

const MembershipAlert = lazy(() =>
    import('pages/LandingPages/MembershipAlert').then((page) => page)
);
const Pricing = lazy(() => import('pages/Pricing'));
const Settings = lazy(() => import('pages/Settings').then((page) => page));
const Contacts = lazy(() => import('pages/Contacts').then((page) => page));
const InvitationPage = lazy(() =>
    import('pages/InvitationPage').then((page) => page)
);
const Notifications = lazy(() =>
    import('pages/Notifications').then((page) => page)
);
const Home = lazy(() => import('pages/Home/Home'));

const renderRoutes = () => (
    <Switch>
        <PrivateRoute exact path={URLS.TIMELINE} component={Timeline} is_lazy />
        <PrivateRoute
            exact
            path={URLS.MEMBERSHIP_ALERT}
            component={MembershipAlert}
            is_lazy
        />

        <PrivateRoute
            exact
            path={`${URLS.ENTRY.EDIT}:entryId`}
            component={Entry}
            is_lazy
        />
        <PrivateRoute
            exact
            path={`${URLS.ENTRY.ROOT}:entryId`}
            component={Entry}
            is_lazy
        />
        <PrivateRoute
            exact
            path={`${URLS.ENTRY.CREATE}`}
            component={Entry}
            is_lazy
        />
        <PrivateRoute path={URLS.AAM.ROOT} component={AllAboutMe} is_lazy />
        <PrivateRoute
            path={`${URLS.JOURNAL}:journalId?`}
            component={JournalTags}
            is_lazy
        />
        <PrivateRoute path={URLS.TAG} component={JournalTags} is_lazy />
        <PrivateRoute path={URLS.BOOK.ROOT} component={Book} is_lazy />
        <PrivateRoute path={URLS.BOOK.ORDERS} component={Book} is_lazy />
        <PrivateRoute
            path={`${URLS.BOOK.PREVIEW}:bookId`}
            component={Book}
            is_lazy
        />
        <PrivateRoute
            path={`${URLS.BOOK.ORDER}:bookId`}
            component={Book}
            is_lazy
        />
        <PrivateRoute path={URLS.SEARCH} component={SearchPage} />
        <PrivateRoute path={URLS.MEDIA.SEARCH} component={SearchPage} />
        <PrivateRoute path={URLS.SETTINGS.ROOT} component={Settings} is_lazy />
        <PrivateRoute path={URLS.CONTACTS.ROOT} component={Contacts} is_lazy />
        <PrivateRoute exact path={URLS.MEDIA.ROOT} component={Media} />
        <PrivateRoute path={`${URLS.MEDIA.VIEW}:mediaID`} component={Media} />
        <PrivateRoute
            exact
            path={URLS.NOTIFICATIONS}
            component={Notifications}
            is_lazy
        />
        <Route exact path={URLS.REMOVE_MY_DATA} component={RemoveMyDataPage} />

        <NotAuthRoute exact path={URLS.HOME} component={Home} is_lazy />
        <NotAuthRoute exact path={URLS.LOGIN} component={Login} is_lazy />
        <NotAuthRoute exact path={URLS.SIGNUP} component={SignUp} is_lazy />
        <NotAuthRoute
            path={`${URLS.SIGNUP}/:uniqueId`}
            component={Login}
            is_lazy
        />

        <Route exact path={URLS.ROOT}>
            {/* {window.location.href.includes('jrnl.com') ? ( */}
                <Redirect to={URLS.HOME} />
            {/* // ) : (
            //     <Redirect to={URLS.LOGIN} />
            // )} */}
        </Route>

        <NotAuthRoute
            exact
            path={URLS.FORGOT_PASS}
            component={ForgotPassword}
            is_lazy
        />
        <NotAuthRoute exact path={URLS.VERIFY} component={Verify} is_lazy />
        <NotAuthRoute path={URLS.PRICING.ROOT} component={Pricing} is_lazy />
        <NotAuthRoute path={URLS.THANKYOU} component={ThankYouPage} />
        <Route
            exact
            path={`${URLS.MOBILE_IMPORT_CONTACTS}`}
            component={MobileContactImport}
        />
        <Route
            exact
            path={`${URLS.REDIRECT_CONTACTS}`}
            component={MobileContactImport}
        />
        <Route
            path={`${URLS.ENTRY_MEDIA}/:entryMediaId`}
            component={EntryMediaFilePage}
        />
        <PrivateRoute path={URLS.DONATION} component={donation} />
        <Route exact path={URLS.PRIVACY} component={Privacy} />
        <Route exact path={URLS.TERMS} component={Terms} />
        <React.Suspense
            fallback={
                <div
                    style={{
                        position: 'fixed',
                        top: '10%'
                    }}>
                    Page is Loading...
                </div>
            }>
            <Route
                path={`${URLS.ENTRY_SHARING}/:uniqueId`}
                component={InvitationPage}
            />
        </React.Suspense>
    </Switch>
);

export default renderRoutes;
