import React from 'react';
import { Text } from 'components';
import { URLS } from 'common/urls';
import { FOOTER_URLS } from 'common/constant';

// Images
import badgeApple from 'assets/images/badge-app-store-rounded.png';
import badgeGoogle from 'assets/images/badge-google-play-rounded.png';
import jrnlIcon from 'assets/images/jrnl-logo_full-light.svg';

// Icons
import facebook from 'assets/images/icons_svg/icon_social-facebook.svg';
import twitter from 'assets/images/icons_svg/icon_social-twitter.svg';
import instagram from 'assets/images/icons_svg/icon_social-instagram.svg';

const Footer = () => {
    return (
        <footer>
            <div className="container">
                <div className="footer__row">
                    <img
                        className="footer__logo"
                        src={jrnlIcon}
                        alt="JRNL Icon"
                    />
                </div>
                {/* <div className="footer__row">
                    <div className="footer__apps">
                        <a
                            className="hero-app__item"
                            href={URLS.ITUNES}
                            target="__blank">
                            <img src={badgeApple} alt="Apple App Store" />
                        </a>
                        <a
                            className="hero-app__item"
                            href={URLS.GPLAY}
                            target="__blank">
                            <img src={badgeGoogle} alt="Google Play Store" />
                        </a>
                    </div>
                </div> */}
                <div className="footer__row">
                    <ul className="footer__social">
                        <li>
                            <a
                                className="footer__facebook"
                                href={FOOTER_URLS.Facebook}
                                target="__blank">
                                <img src={facebook} alt="facebook" />
                            </a>
                        </li>
                        <li>
                            <a
                                className="footer__instagram"
                                href={FOOTER_URLS.Instagram}
                                target="__blank">
                                <img src={instagram} alt="instagram" />
                            </a>
                        </li>
                        <li>
                            <a
                                className="footer__twitter"
                                href={FOOTER_URLS.Twitter}
                                target="__blank">
                                <img src={twitter} alt="twitter" />
                            </a>
                        </li>
                    </ul>
                </div>
                <div className="footer__row">
                    <ul className="footer__links">
                        <li>
                            <a
                                href={FOOTER_URLS.Knowledgebase}
                                target="__blank">
                                <Text tid="Helpdesk" />
                            </a>
                        </li>
                        <li>
                            <a
                                href={FOOTER_URLS.Create_a_Ticket}
                                target="__blank">
                                <Text tid="Create_a_ticket" />
                            </a>
                        </li>
                        <li>
                            <a href={FOOTER_URLS.Blog} target="__blank">
                                <Text tid="Blog" />
                            </a>
                        </li>
                        <li>
                            <a href={URLS.SIGNUP}>Signup</a>
                        </li>
                        <li>
                            <a href={URLS.LOGIN}>Login</a>
                        </li>
                    </ul>
                </div>
                <div className="footer__row">
                    <div className="footer__copyright">
                        <Text tid="JRNL_Inc_All_Rights_Reserved" />
                        <span>/</span>
                        <a href={URLS.PRIVACY} target="__blank">
                            Privacy
                        </a>
                        <span>/</span>
                        <a href={URLS.TERMS} target="__blank">
                            Terms
                        </a>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
