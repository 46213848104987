import 'firebase/auth';
import 'firebase/database';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import qs from 'qs';
import { createStructuredSelector } from 'reselect';
import { connect, useSelector } from 'react-redux';
import { updateUI } from 'redux/actions/ui';
import renderRoutes from 'router/createRoutes';

import {
    getCalendar,
    getFacebookState,
    getInstagramState,
    getLoadingProgress,
    getUI,
    getUser,
    getUserTimeLineReadingType
} from 'redux/selectors';
import { useHistory, useLocation } from 'react-router-dom';

// Dialogs
import SocialMediaPhotosDialog from 'pages/Dialogs/SocialMedia/SocialMediaPhotosDialog';

// Actions
// import * as EntryActions from 'redux/actions/entry';
import * as PublicUserActions from 'redux/actions/publicuser';
import * as UserActions from 'redux/actions/user';
import { reset_password } from 'redux/actions/user';
import * as InstagramActions from 'redux/actions/instagram';
import * as FacebookActions from 'redux/actions/facebook';
import * as NotificationActions from 'redux/actions/notification';
import { checkDevice } from 'common/utils/check-device-type';
import { activate } from 'redux/actions/registration';
import { toggleImageFullScreen } from 'common/utils/images/to_full_screen';
//  Assets
import 'assets/css/fontawesome.css';
import 'assets/scss/style.scss';
import 'assets/css/responsive.css';
import 'assets/fonts/saasuma-icons/style.css';
import jrnlPreloader from 'assets/images/preloader.gif';

import timelineBg from 'assets/images/timeline-images/timeline-bg.png';
import { FilterDrawer, Header } from 'components';
import TimelineFiltersContainer from 'pages/Timeline/TimelineFiltersContainer';
import { AndroidBanner, IosBanner } from 'components/mobile-banners';
import { URLS } from 'common/urls';
import {
    ANDROID,
    ERROR_MESSAGES,
    FAVICON_TEXT,
    IPHONE,
    notification_comment,
    SOCIAL_TYPE_FACEBOOK,
    SOCIAL_TYPE_INSTAGRAM,
    SUMMARY_VIEW_TYPE,
    VIEW_WIDTH_MAP
} from 'common/constant';
import withSocialType from 'common/utils/components/withSocialType';
import AccountDuplicateDialog from 'pages/Dialogs/AccountDuplicateDialog';
import { Backdrop, Modal, Snackbar } from '@material-ui/core';
import { ActivatedModal } from 'components/activated-modal';
import { PasswordChangeModal } from 'components/password-change-modal';
import Alert from '@material-ui/lab/Alert';
// Firebase
import {
    db,
    firebase_console,
    FIREBASE_DB_ROOT,
    updateFirebaseUserUID
} from 'common/firebase';
import { reset_search_page } from './redux/actions/search';
import useDeepCompareEffect from 'use-deep-compare-effect';
import FreeAccountPermissionDialog from 'pages/Dialogs/ErrorModals/FreeAccountPermissionDialog';
// import withFreeAccountCheck from 'common/utils/components/withFreeAccountCheck';
import setting from 'common/configs/setting';
import { showBookPreviewDialog } from 'common/utils/dialog-utils';
import { checkResponseSuccess } from 'common/utils/responseUtils';
import { useUpdateCheck } from 'react-update-notification';
import 'lazysizes';
import 'lazysizes/plugins/parent-fit/ls.parent-fit';
import SettingsSidebar from 'components/SettingsSidebar';

const App = ({
    dispatch,
    ui,
    user,
    match,
    instagram,
    facebook,
    reading_type,
    calendar
}) => {
    const location = useLocation();
    const history = useHistory();
    const [instagramConnected, setInstagramConnected] = useState(false);
    const [userName, serUserName] = useState('');
    const [pass_data, setPassData] = useState({
        uid: '',
        token: '',
        errors: '',
        hasError: false
    });

    const loadingProgress = useSelector(getLoadingProgress);
    const [snackBarOptions, setSnackBarOption] = React.useState({
        open: false,
        vertical: 'top',
        horizontal: 'center',
        iphone: false,
        android: false
    });

    const user_id = user?.id;
    const { vertical, horizontal, open } = snackBarOptions;
    const [openModal, setOpenModal] = useState(false);
    const [passwordChangeModalOpen, setPasswordChangeModalOpen] = useState(
        false
    );
    const [firebaseUID, setFirebaseUID] = useState(undefined);

    const handleCloseBanner = () => {
        setSnackBarOption({
            ...snackBarOptions,
            open: false,
            iphone: false,
            android: false
        });
    };
    // eslint-disable-next-line
    const dispatchIfHasNewComment = useCallback(
        (notifications) => {
            const lastNotification = notifications[notifications?.length - 1];
            const entryIdInLocation = location.pathname.match(/(\d+)/)
                ? Number(location.pathname.match(/(\d+)/)[0])
                : 0;
            if (
                lastNotification?.data?.type === notification_comment &&
                entryIdInLocation === lastNotification.data.entry_id &&
                location.pathname.includes(URLS.ENTRY.ROOT)
            ) {
                dispatch(
                    updateUI({
                        hasNewComment: true,
                        lastNotification
                    })
                );
            }
        },
        [dispatch, location.pathname]
    );
    // firebase authenticate
    useEffect(() => {
        firebase_console
            .auth()
            .signInWithEmailAndPassword(
                setting.firebase_user.email,
                setting.firebase_user.password
            )
            .then((response) => {
                const uid = response.user.uid;
                setFirebaseUID(uid);
                updateFirebaseUserUID(uid);
            });
    }, []);
    // Handle the notifications that come from backend
    useEffect(() => {
        if (user_id && firebaseUID) {
            db.ref(`${FIREBASE_DB_ROOT}${firebaseUID}/${user_id}`).on(
                'value',
                (snapshot) => {
                    const notifications = [];
                    snapshot.forEach((snap) => {
                        notifications.push({
                            key: snap.key,
                            data: snap.val()
                        });

                        if (snap.val().data) {
                            const newVal = {};

                            Object.keys(snap.val()).forEach((key) => {
                                if (key !== 'data' && key !== 'key') {
                                    newVal[key] = snap.val()[key];
                                }
                            });

                            newVal['read_test'] = true;

                            db.ref(
                                `${FIREBASE_DB_ROOT}${firebaseUID}/${user_id}/${snap.key}`
                            ).set(newVal);
                        }
                    });

                    dispatch(
                        NotificationActions.addNotifications(notifications)
                    );
                    dispatchIfHasNewComment(notifications);
                }
            );
        }
        return () => {
            if (user_id && firebaseUID) {
                db.ref(`${FIREBASE_DB_ROOT}${firebaseUID}${user_id}`).off(
                    'value'
                );
            }
        };
    }, [user_id, dispatch, dispatchIfHasNewComment, firebaseUID]);
    useEffect(
        () => {
            const device = checkDevice();
            if (device === ANDROID) {
                setTimeout(
                    () =>
                        setSnackBarOption({
                            ...snackBarOptions,
                            open: true,
                            android: true
                        }),
                    2000
                );
            } else if (device === IPHONE) {
                setTimeout(
                    () =>
                        setSnackBarOption({
                            ...snackBarOptions,
                            open: true,
                            iphone: true
                        }),
                    2000
                );
            }
        },
        // eslint-disable-next-line
        []
    );
    useEffect(() => {
        // clear dialog
        dispatch(
            updateUI({
                dialogs: []
            })
        );
    }, [dispatch]);
    useEffect(() => {
        if (location.hash.includes('password_set_confirm')) {
            const indexOfCode = location.hash.indexOf('m/') + 2;
            const fields = location.hash.substring(indexOfCode);
            const indexUid = fields.indexOf('/');
            const uid = fields.substring(0, indexUid);
            const token = fields.substring(indexUid + 1, fields.length - 1);
            setPassData({ ...pass_data, uid, token });
            setPasswordChangeModalOpen(true);
        }
        // eslint-disable-next-line
    }, []);
    useEffect(() => {
        if (location.hash.includes('activate')) {
            const indexCode = location.hash.indexOf('activate/');
            const activation_code = location.hash.substring(indexCode + 9);
            dispatch(activate(activation_code))
                .then((response) => {
                    if (response?.payload?.status === 201) {
                        serUserName(response?.payload?.data?.username);
                        setOpenModal(true);
                    }
                })
                .catch((error) => {
                    if (error.error?.response?.status === 400) {
                        history.push(URLS.LOGIN);
                    }
                });
        }
        // eslint-disable-next-line
    }, []);
    useEffect(() => {
        // check if the instagram code exists
        const queryObj = qs.parse(location.search || location.hash, {
            ignoreQueryPrefix: true
        });

        if (!instagramConnected && queryObj.state) {
            // call parent window function
            window.opener &&
                window.opener.location &&
                window.opener.location.connectSocialConnect(queryObj);

            // close current window
            window.close();
        }
    }, [location, instagramConnected, ui]);

    useDeepCompareEffect(() => {
        if (ui.socialConnect.data) {
            const queryObj = ui.socialConnect.data;
            const state = JSON.parse(queryObj.state);
            if (state.type !== 'login') {
                switch (state.provider) {
                    case SOCIAL_TYPE_INSTAGRAM: {
                        setInstagramConnected(true);
                        dispatch(
                            InstagramActions.connectInstagram(queryObj.code)
                        ).then((response) => {
                            if (
                                response.payload?.data?.username !==
                                user?.username
                            ) {
                                dispatch(
                                    updateUI({
                                        showInstagram: true,
                                        dialogs: [
                                            withSocialType(
                                                AccountDuplicateDialog,
                                                SOCIAL_TYPE_INSTAGRAM
                                            )
                                        ]
                                    })
                                );
                            } else if (
                                checkResponseSuccess(response.payload?.status)
                            ) {
                                if (state?.position === 'setting') {
                                    dispatch(UserActions.fetch());
                                    history.push(URLS.SETTINGS.SOCIALCONNECT);
                                } else {
                                    dispatch(
                                        updateUI({
                                            showedInstagram: true,
                                            dialogs: [
                                                ...(ui.dialogs || []),
                                                withSocialType(
                                                    SocialMediaPhotosDialog,
                                                    SOCIAL_TYPE_INSTAGRAM
                                                )
                                            ]
                                        })
                                    );
                                }
                            }
                        });
                        break;
                    }
                    case SOCIAL_TYPE_FACEBOOK: {
                        setInstagramConnected(true);
                        dispatch(
                            FacebookActions.connectFacebook(
                                queryObj['#access_token']
                            )
                        ).then((response) => {
                            if (
                                response.payload?.data?.username !==
                                user?.username
                            ) {
                                dispatch(
                                    updateUI({
                                        showInstagram: true,
                                        dialogs: [
                                            withSocialType(
                                                AccountDuplicateDialog,
                                                SOCIAL_TYPE_FACEBOOK
                                            )
                                        ]
                                    })
                                );
                            } else if (
                                checkResponseSuccess(response.payload?.status)
                            ) {
                                if (state?.position === 'setting') {
                                    dispatch(UserActions.fetch());
                                    history.push(URLS.SETTINGS.SOCIALCONNECT);
                                } else {
                                    dispatch(
                                        updateUI({
                                            showedInstagram: true,
                                            dialogs: [
                                                ...(ui.dialogs || []),
                                                withSocialType(
                                                    SocialMediaPhotosDialog,
                                                    SOCIAL_TYPE_FACEBOOK
                                                )
                                            ]
                                        })
                                    );
                                }
                            }
                        });
                        break;
                    }
                    default: {
                        break;
                    }
                }
            } else {
                switch (state.provider) {
                    case SOCIAL_TYPE_INSTAGRAM: {
                        break;
                    }
                    case SOCIAL_TYPE_FACEBOOK: {
                        dispatch(
                            FacebookActions.loginFacebook(
                                queryObj['#access_token']
                            )
                        );
                        break;
                    }
                    default: {
                        break;
                    }
                }
            }
            dispatch(
                updateUI({
                    socialConnect: {}
                })
            );
        }
    }, [ui.socialConnect]);

    const closeModal = useCallback(() => {
        if (openModal) {
            setOpenModal(false);
        } else if (passwordChangeModalOpen) {
            setPasswordChangeModalOpen(false);
        }
    }, [openModal, passwordChangeModalOpen]);

    const userAuthorized = useMemo(() => user?.isAuthorized, [user]);
    const userSharing = useMemo(() => user?.sharing, [user]);
    const userId = useMemo(() => user?.id, [user]);

    useEffect(() => {
        if (userAuthorized) {
            setOpenModal(false);
            dispatch(UserActions.fetch()).then((response) => {
                if (userSharing) {
                    dispatch(PublicUserActions.fetchPublicUser(userId));
                }
                dispatch(
                    updateUI({
                        viewWidth:
                            VIEW_WIDTH_MAP[response.payload.data.timeline_width]
                    })
                );
            });

            // dispatch(EntryActions.setLoadingProgress());
            // dispatch(ShareContactListsActions.fetch());
            // dispatch(JournalActions.fetch());
            // dispatch(EntryActions.fetch());
        }
        return () => {};
    }, [dispatch, userSharing, userId, userAuthorized]);

    const hideDialog = useCallback(
        (dialog) => {
            const dialogs = [...ui.dialogs];
            dialogs.splice(-1, 1);

            dispatch(
                updateUI({
                    dialogs
                })
            );
        },
        [dispatch, ui]
    );

    const renderDialogs = useCallback(() => {
        return (ui.dialogs || []).map((Dialog, index) => (
            <Dialog key={index} open={true} close={hideDialog} />
        ));
    }, [ui, hideDialog]);

    // hide book preview dialog
    const hideBookPreviewDialog = (bookId) => {
        showBookPreviewDialog(bookId, false);
    };
    // render book preview dialogs
    const renderBookPreviewDialogs = () => {
        return Object.keys(ui.bookPreviewDialogs).map((bookId) => {
            const Dialog = ui.bookPreviewDialogs[bookId];

            return (
                <Dialog
                    key={bookId}
                    open={ui.bookPreviewDialogShow[bookId]}
                    close={() => hideBookPreviewDialog(bookId)}
                />
            );
        });
    };

    const change_password = useCallback(
        (e, value) => {
            e.preventDefault();
            const { uid, token } = pass_data;
            const { new_password1, new_password2 } = value;
            dispatch(
                reset_password({ uid, token, new_password1, new_password2 })
            ).then((response) => {
                if (response.error) {
                    const errors =
                        response.error?.response?.data?.validation_errors;
                    const errorMessage = errors.new_password1[0];
                    setPassData({
                        ...pass_data,
                        hasError: true,
                        errors: errorMessage
                    });
                } else {
                    setPasswordChangeModalOpen(false);
                    history.push(URLS.LOGIN);
                }
            });
        },
        [dispatch, history, pass_data, setPasswordChangeModalOpen]
    );

    const renderChangePasswordModal = useCallback(() => {
        if (!passwordChangeModalOpen) {
            return;
        }
        return (
            <Modal open={passwordChangeModalOpen} style={{ top: '20%' }}>
                <PasswordChangeModal
                    closeModal={closeModal}
                    change_password={change_password}
                />
            </Modal>
        );
    }, [passwordChangeModalOpen, closeModal, change_password]);

    const renderHeaderAndSidebars = useCallback(() => {
        const showBackgroundImage = () => {
            return ui.donationView
                ? null
                : user.wallpaper_webp_image_url ||
                      user.wallpaper_image_url ||
                      timelineBg;
        };
        const drawerCond = [URLS.TIMELINE, URLS.JOURNAL].some((url) => {
            if (url === URLS.JOURNAL) {
                const splitUrl = location.pathname.split('/');
                return Boolean(
                    location.pathname.includes(url) &&
                        splitUrl[splitUrl.length - 1] !== ''
                );
            } else {
                return location.pathname.includes(url);
            }
        });

        const is_donation_page = URLS.DONATION === location.pathname;

        return (
            <React.Fragment>
                {!is_donation_page && (
                    <div className="bg-fill">
                        <div
                            className="bg-fill__img"
                            style={{
                                backgroundImage: `url(${showBackgroundImage()})`
                            }}
                        />
                    </div>
                )}
                {drawerCond && (
                    <React.Fragment>
                        <FilterDrawer open={ui.filterDrawerOpen}>
                            <TimelineFiltersContainer />
                        </FilterDrawer>

                        <SettingsSidebar open={ui.sidebarDrawerOpen} />
                    </React.Fragment>
                )}
            </React.Fragment>
        );
        // eslint-disable-next-line
    }, [ui]);

    useEffect(() => {
        for (const element of FAVICON_TEXT) {
            if (location.pathname === element.url) {
                document.title = element.title;
            }
        }
        if (
            ui.dialogs &&
            !location.pathname.includes(URLS.MEDIA.ROOT) &&
            !ui.showDialog
        ) {
            // hideDialog();
        }
        dispatch(reset_search_page());

        if (
            ui.donationView === false &&
            location.pathname.includes(URLS.DONATION)
        ) {
            dispatch(
                updateUI({
                    donationView: true
                })
            );
        } else if (
            ui.donationView === true &&
            !location.pathname.includes(URLS.DONATION)
        ) {
            dispatch(
                updateUI({
                    donationView: false
                })
            );
        }
        // eslint-disable-next-line
    }, [location.pathname, dispatch, ui.donationView]);

    useEffect(() => {
        if (
            user.is_ty &&
            !user.is_free &&
            !user.is_pro &&
            localStorage.getItem('go-to-v4') !== 'false'
        ) {
            localStorage.setItem('go-to-v4', 'false');
            window.location.href = '/v3';
        }
    });

    const renderMobileBanners = useCallback(() => {
        return snackBarOptions.android ? (
            <AndroidBanner onClose={handleCloseBanner} />
        ) : snackBarOptions.iphone ? (
            <IosBanner onClose={handleCloseBanner} />
        ) : undefined;
        // eslint-disable-next-line
    }, [snackBarOptions.open]);

    useEffect(() => window.scroll(0, 0), [location.pathname]);

    const accountActivated = useCallback(() => {
        if (!openModal) {
            return;
        }
        return (
            <Modal open={openModal}>
                <ActivatedModal closeModal={closeModal} userName={userName} />
            </Modal>
        );
    }, [openModal, userName, closeModal]);

    const renderAlertInPasswordChangeError = useCallback(() => {
        return (
            <Snackbar open={pass_data.hasError}>
                <Alert severity="error">{pass_data.errors}</Alert>
            </Snackbar>
        );
        // eslint-disable-next-line
    }, [pass_data.hasError]);

    const NotificationContainer = () => {
        const { status, reloadPage } = useUpdateCheck({
            type: 'interval',
            interval: 24 * 3600 * 1000
        });

        if (status === 'checking' || status === 'current') {
            return null;
        }

        return (
            <div>
                <button type="button" onClick={reloadPage}>
                    Refresh to update the app
                </button>
            </div>
        );
    };

    const headerCond = ![
        URLS.PRICING.ROOT,
        URLS.THANKYOU,
        URLS.LOGIN,
        URLS.SIGNUP,
        URLS.FORGOT_PASS,
        URLS.VERIFY,
        URLS.MEMBERSHIP_ALERT
    ].some((item) => location.pathname.includes(item));

    const renderLoader = useCallback(() => {
        return (
            <Backdrop open style={{ zIndex: 9999 }}>
                <section className="loader-section">
                    <div className="loader">
                        <img src={jrnlPreloader} alt="loader" />
                    </div>
                </section>
            </Backdrop>
        );
    }, []);

    useEffect(() => {
        if (loadingProgress === 99) {
            document.body.style.overflow = '';
        }
    }, [loadingProgress]);

    useEffect(() => {
        if (userAuthorized) {
            dispatch(
                updateUI({
                    summaryView: Boolean(reading_type === SUMMARY_VIEW_TYPE),
                    summaryViewWithEntry: {}
                })
            );
        }
        //eslint-disable-next-line
    }, [userAuthorized]);

    // Error Messages Handler
    const { errors = {} } = ui;
    useDeepCompareEffect(() => {
        if (!errors) {
            return;
        }

        const { type } = errors;
        switch (type) {
            case ERROR_MESSAGES.FREE_ACCOUNT_PERMISSION_ERROR: {
                // TODO: Render the Free Account Error Prompts
                dispatch(
                    updateUI({
                        dialogs: [FreeAccountPermissionDialog],
                        errors: {} // this is important, not change to null or undefined. it will cause the errors
                    })
                );
                break;
            }
            default: {
                break;
            }
        }
    }, [ui.errors]);

    useEffect(() => {
        const script = document.createElement('script');
        const noscript = document.createElement('noscript');

        const faceBookPixelCodeForScriptTag =
            "!function(f,b,e,v,n,t,s){if(f.fbq)return;n=f.fbq=function(){n.callMethod?n.callMethod.apply(n,arguments):n.queue.push(arguments)};if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';n.queue=[];t=b.createElement(e);t.async=!0;t.src=v;s=b.getElementsByTagName(e)[0];s.parentNode.insertBefore(t,s)}(window, document,'script','https://connect.facebook.net/en_US/fbevents.js');fbq('init', '998781300854173');fbq('track', 'PageView');";
        const faceBookPixelCodeForNoScriptTag =
            "<img height='1' width='1' style='display:none'src='https://www.facebook.com/tr?id=998781300854173&ev=PageView&noscript=1'/>";
        script.append(faceBookPixelCodeForScriptTag);
        noscript.append(faceBookPixelCodeForNoScriptTag);

        document.head.appendChild(script);
        document.head.appendChild(noscript);
    }, []);

    return (
        <>
            <NotificationContainer></NotificationContainer>
            <div>
                <div
                    id="image_full_screen_container"
                    onClick={toggleImageFullScreen}></div>
                {headerCond && <Header />}
                {userAuthorized && renderHeaderAndSidebars()}
                {renderRoutes()}
                {renderDialogs()}
                {renderBookPreviewDialogs()}
                {accountActivated()}
                {renderChangePasswordModal()}
            </div>
            {/* <Snackbar
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center'
                    // https://v4.mui.com/ru/api/snackbar/#snackbar-api
                    // backgroundColor: 'white'
                }}
                open={open}
                key={vertical + horizontal}
                style={{
                    width: '100%'
                    // https://v4.mui.com/ru/api/snackbar/#snackbar-api
                    // zIndex: '9999'
                }}>
                {renderMobileBanners()}
            </Snackbar> */}
            {renderAlertInPasswordChangeError()}
            {(loadingProgress !== 100 || ui.load) && renderLoader()}
        </>
    );
};

const state = createStructuredSelector({
    ui: getUI,
    user: getUser,
    instagram: getInstagramState,
    facebook: getFacebookState,
    reading_type: getUserTimeLineReadingType,
    calendar: getCalendar
});
export default connect(state)(App);
