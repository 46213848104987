import React, { useEffect } from 'react';
import { Footer } from 'components';
import { connect } from 'react-redux';
import { URLS } from 'common/urls';

// images
import partner1 from 'assets/images/partner-1.png';
import partner2 from 'assets/images/partner-2.png';
import partner3 from 'assets/images/partner-3.png';
import partner4 from 'assets/images/partner-4.png';
import partner5 from 'assets/images/partner-5.png';
import partner6 from 'assets/images/partner-6.png';
import partner7 from 'assets/images/partner-7.png';
import partner8 from 'assets/images/partner-8.png';
import partner9 from 'assets/images/partner-9.png';
import badgeApple from 'assets/images/badge-app-store-rounded.png';
import badgeGoogle from 'assets/images/badge-google-play-rounded.png';
import heroImgTop from 'assets/images/frontend/home/hero-top.jpg';
import heroImgSm from 'assets/images/frontend/home/hero-sm.jpg';
import heroImgBottom from 'assets/images/frontend/home/hero-bottom.jpg';
import legacyImg1 from 'assets/images/frontend/home/legacy-1.jpg';
import legacyImg2 from 'assets/images/frontend/home/legacy-2.jpg';
import privacyImg from 'assets/images/frontend/home/privacy.jpg';
import shareImg from 'assets/images/frontend/home/share.jpg';

const Login = () => {
    useEffect(() => {
        document.title = 'JRNL - Unleash Your Mind';
    }, []);

    return (
        <div className="front">
            <main>
                <section className="hero">
                    <img
                        className="hero-img hero-img--top"
                        src={heroImgTop}
                        alt=""
                    />
                    <div className="container">
                        <h1 className="hero__title">Unleash Your Mind</h1>
                        <div className="hero__subtitle">
                            Here, your mind can run free. This is your space for
                            your thoughts. This is JRNL.
                        </div>
                        <a
                            className="hero__cta btn btn-gradient btn--lg"
                            href={URLS.SIGNUP}>
                            Start Journaling
                        </a>
                        {/* <div className="hero__download">Download the app</div>
                        <div className="hero-apps">
                            <a
                                className="hero-app__item"
                                href={URLS.ITUNES}
                                target="__blank">
                                <img src={badgeApple} alt="" />
                            </a>
                            <a
                                className="hero-app__item"
                                href={URLS.GPLAY}
                                target="__blank">
                                <img src={badgeGoogle} alt="" />
                            </a>
                        </div> */}
                        <div className="hero-sm">
                            <img src={heroImgSm} alt="" />
                        </div>
                    </div>
                    <div className="hero-img--bottom">
                        <img src={heroImgBottom} alt="" />
                    </div>
                </section>
                <section className="partners">
                    <a href="/" className="partner-item">
                        <img src={partner1} alt="partner" />
                    </a>
                    <a href="/" className="partner-item">
                        <img src={partner2} alt="partner" />
                    </a>
                    <a href="/" className="partner-item">
                        <img src={partner3} alt="partner" />
                    </a>
                    <a href="/" className="partner-item">
                        <img src={partner4} alt="partner" />
                    </a>
                    <a href="/" className="partner-item">
                        <img src={partner5} alt="partner" />
                    </a>
                    <a href="/" className="partner-item">
                        <img src={partner6} alt="partner" />
                    </a>
                    <a href="/" className="partner-item">
                        <img src={partner7} alt="partner" />
                    </a>
                    <a href="/" className="partner-item">
                        <img src={partner8} alt="partner" />
                    </a>
                    <a href="/" className="partner-item">
                        <img src={partner9} alt="partner" />
                    </a>
                </section>
                <section className="legacy section">
                    <div className="container">
                        <div className="section__row">
                            <div className="section__img">
                                <img src={legacyImg1} alt="" />
                            </div>
                            <div className="section__copy">
                                <h2>What&apos;s Your Legacy?</h2>
                                <p>
                                    The world contains a rich collection of
                                    answers to the question of where we came
                                    from, why we are here, and where we are
                                    going. Regardless of what those answers are,
                                    right now, we&apos;re here, and we will all
                                    leave a legacy.
                                </p>
                                <p>What will yours be?</p>
                                <a href="#!" className="btn btn-gradient">
                                    Record my Legacy
                                </a>
                            </div>
                        </div>
                        <div className="section__row section__row--reverse">
                            <div className="section__img">
                                <img src={legacyImg2} alt="" />
                            </div>
                            <div className="section__copy">
                                <h2>Capture Everything Important</h2>
                                <p>
                                    JRNL can preserve the words, images, video,
                                    audio, and more that you want to echo on and
                                    stay here after you are gone. From
                                    meaningful insights and eyewitness
                                    experiences to the corny jokes you hope your
                                    kids will tell to their kids.
                                </p>
                                <p>
                                    By capturing these moments, you control the
                                    narrative you leave behind and the message
                                    that resonates through future generations.
                                </p>
                                <a href="#!" className="btn btn-gradient">
                                    Preserve my Memories
                                </a>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="real-you section">
                    <div className="container">
                        <span
                            className="real-you__emoji"
                            role="img"
                            aria-label="emoji">
                            &#128540;
                        </span>
                        <div className="section__title">
                            Welcome to the <u>Real</u> You
                        </div>
                        <div className="real-you__copy">
                            <p>
                                There are no bad angles here. No bad lighting.
                                No smiling too much or not enough. This is the
                                time to take the mask off, put the sweats on,
                                and be you.
                            </p>
                            <p>
                                There&apos;s no need to worry about likes,
                                views, or reshares. You don&apos;t have to be
                                anyone else&apos;s version of
                                &apos;perfect&apos; here. You just have to be.
                            </p>
                            <p>Welcome to the real you.</p>
                        </div>
                        <a className="cta__btn btn btn-white btn--md" href="#!">
                            Get Started Now
                        </a>
                    </div>
                    <div className="real-you-bg"></div>
                </section>
                <section className="privacy section">
                    <div className="container">
                        <div className="section__row">
                            <div className="section__img">
                                <img src={privacyImg} alt="" />
                            </div>
                            <div className="section__copy">
                                <h2>Rights Respected</h2>
                                <p>
                                    A notebook won&apos;t judge you and neither
                                    do we. To think and speak freely is an
                                    essential human right and one that we at
                                    JRNL greatly respect.
                                </p>
                                <a className="btn btn-gradient" href="#!">
                                    Speak your mind
                                </a>
                            </div>
                        </div>
                        <div className="section__row section__row--reverse">
                            <div className="section__img">
                                <img src={shareImg} alt="" />
                            </div>
                            <div className="section__copy">
                                <h2>As Private as You Want</h2>
                                <p>
                                    Unless you make a conscious choice to share
                                    with others, JRNL will remain your private
                                    safe space. This is your inner world, and we
                                    respect how sacred that is.
                                </p>
                                <a className="btn btn-gradient" href="#!">
                                    Create your private space
                                </a>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="great-features">
                    <div className="container">
                        <div className="section__title">
                            Loaded With Great Features
                        </div>
                        <div className="great-features__copy">
                            <p>
                                JRNL has so many great features it would be
                                better to sign up for a free account and explore
                                them yourself. You can create entries with text,
                                images, video, audio, and imported social media
                                posts.
                            </p>
                            <p>
                                You can share your entries, create shared
                                journals, print your content to archival-quality
                                hardcover books, select beneficiaries to pass
                                your legacy on to, set reminders, email or text
                                your journal entries, and so much more!
                            </p>
                        </div>
                        <a
                            className="cta__btn btn btn-gradient btn--md"
                            href="#!">
                            Start Journaling Now!
                        </a>
                        <div className="great-features-bg"></div>
                    </div>
                </section>
            </main>
            <Footer />
        </div>
    );
};

const states = (state, ownProps) => ({
    ...state
});
export default connect(states)(Login);
